@import "../../styles/variables.scss";

.container {
  width: 100%;
  word-wrap: break-word;
  overflow: hidden;
  padding: 2.5em auto;

  .title {
    width: 100%;
    color: var(--text-color);
    .emphase {
      color: var(--text-emphase-color);
    }
  }

  .text {
    max-width: 470px;
    font-size: 1.1rem;
    line-height: 1.7;
    .emphase {
      font-weight: 600;
      color: var(--text-emphase-color);

      &:last-child {
        display: block;
        margin-top: 1em;
      }
    }
  }

  .containerTheme {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.25em;

    .titleTheme {
      font-size: 1.25rem;
      margin-bottom: 0.6em;
    }
  }

  .explore {
    display: none;
  }

  .containerImg {
    width: 100%;

    .dotsPurple {
      display: none;
    }

    .img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    display: flex;
    margin-top: 6em;
    height: calc(100vh - 6em);
    padding-bottom: 0;

    .title {
      font-size: 4rem;
      margin-bottom: 0.8em;
    }

    .text {
      margin-bottom: 3.2em;
    }

    .containerTheme {
      align-items: flex-start;
    }

    .containerImg {
      position: relative;
      width: 80%;
      padding-right: 1em;

      .dotsPurple {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
