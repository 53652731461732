@import "../../styles/variables.scss";

@keyframes toggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: scaleX(1.5) translateX(0);
  }
  75% {
    transform: scaleX(1.5) translateX(24px);
  }
  100% {
    transform: scaleX(1) translateX(50px);
  }
}

@keyframes toggleReverse {
  0% {
    transform: scaleX(1) translateX(50px);
  }
  25% {
    transform: scaleX(1.5) translateX(24px);
  }
  75% {
    transform: scaleX(1.5) translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.toggleSwitch {
  position: relative;
  min-width: 48px;
  height: 30px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  &Checkbox {
    display: none;
  }

  &Label {
    display: block;
    width: 100%;
    height: 100%;
    padding: 2px;
  }

  &Inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: var(--header-rounded-shape);
    &::before {
      content: "";
      position: absolute;
      background-color: #eee;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  &Thumb {
    display: block;
    width: 24px;
    height: 24px;
    background: var(--text-color);
    position: absolute;
    margin: 3px;
    top: 0;
    left: 0;
    border-radius: 50%;
    cursor: pointer;
    transform-origin: left;
    animation: toggleReverse ease-in-out 0.2s forwards;
  }

  &Checkbox:checked {
    + .toggleSwitchInner {
      &::before {
        opacity: 0;
      }
      + .toggleSwitchLabel .toggleSwitchThumb {
        animation: toggle ease-in-out 0.2s forwards;
      }
    }
  }
}
