/* ------- PURPLE ------- */
$purple900: #352bc1;
$purple700: #665aff;
$purple600: #9992ff;
$purple500: #cdcaff;
$purple300: #f4f3ff;

/* ------- BLUE ------- */
$blue900: #34a1cd;
$blue700: #58cfff;
$blue600: #9ce2ff;
$blue500: #d9f4ff;
$blue300: #e9f9ff;

/* ------- PINK ------- */
$pink900: #e7a741;
$pink700: #ffc261;
$pink600: #ffd697;
$pink500: #feecd0;
$pink300: #fff4e4;

/* ------- RED ------- */
$red900: #d74848;
$red700: #f66565;
$red600: #f8979b;
$red500: #ffcdcf;
$red300: #fff5f6;

/* ------- GREY ------- */
$grey900: #130f49;
$grey700: #55527c;
$grey600: #b4b2c5;
$grey500: #d9d9e2;
$grey300: #f8f8fa;
