.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .containerTheme {
    display: flex;

    .titleTheme {
      margin-right: 1em;
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
